import React from 'react'
import { graphql } from 'gatsby'
import { Container } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import ModalSimple from '@solid-ui-blocks/Modal/Block02'
import Header from '@solid-ui-blocks/Header/Block01'
import Info from '@solid-ui-blocks/FeaturesWithPhoto/Block01'
import Content from '@solid-ui-blocks/Content/Block01'
import Quote from '../../blocks/Quotes/Block01'
import Data from '../../components/DatawrapperEmbed/DatawrapperEmbed'
import Milestones from '@solid-ui-blocks/Features/Block01'
import Stats from '@solid-ui-blocks/Stats/Block01'
import DrillMap from '../../blocks/DrillHolesMap/Block01'
import Download from '@solid-ui-blocks/CallToAction/Block02'
import CrossSection from '@solid-ui-blocks/FeaturesWithPhoto/Block03'
import Footer from '@solid-ui-blocks/Footer/Block01'
import { normalizeBlockContentNodes } from '@blocks-helpers'

const ProjectItemPage = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  return (
    <Layout {...props}>
      <Seo title='Castle East Project' />
      {/* Modals */}
      <ModalSimple content={content['privacy-policy']} />
      <ModalSimple content={content['disclaimer']} />
      {/* Blocks */}
      <Header content={content['header']} />
      <Divider space='5' />
      <Divider space='5' />
      <Info content={content['overview']} />
      <Divider space='4' />
      <Download content={content['download']} />
      <Divider space='4' />
      <Container variant='narrow'>
      <Milestones content={content['milestones-1']} />
      <Divider space='1' />
      <Stats content={content['stats']} />
      <Divider space='1' />
      </Container>
      <Divider space='4' />
      <Info content={content['overview-2']} reverse />
      <Divider space='4' />
      <Container variant='narrow'>
        <Info content={content['info-1']} reverse />
        <Divider space='4' />
        <Info content={content['info-2']} />
        <Divider space='3' />
        </Container>
        
        <Quote content={content['content-two']} />

        <Container variant='narrow'>
        <Divider space='3' />
        <Content content={content['content-three']} />     
        <Divider space='2' />
        <CrossSection content={content['cross-section-1']} />
        <Divider space='4' />
        <Content content={content['content-seven']} />
        <Divider space='2' />
        <CrossSection content={content['cross-section-2']} />
      </Container>
      <Divider space='3' />
        <Container variant='narrow'>
        <Content content={content['content-six']} />
        <Divider space='2' />
          <Data content={content['data-1']} />
        </Container>
      <Divider space='4' />
      <DrillMap content={content['drill-map']} />
      <Divider space='3' />
      <CrossSection content={content['cross-section-3']} />
      <Divider space='2' />
      <Content content={content['content-four']} />
      <Divider space='5' />
      <CrossSection content={content['cross-section-4']} />
      <Divider space='2' />
      <Content content={content['content-five']} />
      <Divider space='5' />
      <Footer content={content['footer']} />
    </Layout>
  )
}

export const query = graphql`
  query siteProjectsCastleEastBlockContent {
    allBlockContent(
      filter: { page: { in: ["site/projects/castle-east", "site/shared"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`
export default ProjectItemPage
